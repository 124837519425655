<template>
  <div class="Aboutus">
    <div class="bigImg">
    </div>
    <div class="iconBox">
      <div>
        <p>
          <img src="../../static/aboutus/1.png" alt="">
        </p>
        <span>专业专注</span>
      </div>
      <div>
        <p>
          <img src="../../static/aboutus/2.png" alt="">
        </p>
        <span>诚信经营</span>
      </div>
      <div>
        <p>
          <img src="../../static/aboutus/3.png" alt="">
        </p>
        <span>一站式服务</span>
      </div>
      <div>
        <p>
          <img src="../../static/aboutus/4.png" alt="">
        </p>
        <span>科技助力</span>
      </div>
    </div>
    <div class="contentBox">
      <div class="letterBox">
        <div class="lbSmallBox">
          <div>
            <p>
              <span>金银业贸易场AA类行员</span>
              <span>No. 081</span>
            </p>
          </div>
          <div>
            Hong Kong Red Lion Capital Limited，简称RLC，是我们为大众投资者倾心打造的独立品牌，旨在向客户提供低点差和低利率的交易产品，让客户的投资物超所值，没有后顾之忧。公司依照全球最受欢迎的交易方式，专注为客户提供安全、稳定、高效的一站式交易服务。自品牌成立以来，香港红狮集团有限公司一直秉承“客户至上，诚信为本”的经营理念，致力于高科技智能化交易平台的建设，专业、专注、规范操作让香港红狮集团有限公司赢得了客户的高度信赖，同时也得到了业界的广泛关注和大力支持。
          </div>
        </div>
        <img src="../../static/aboutus/pingxing.png" alt="">
      </div>
      <div class="letterBox">
        <div class="lbSmallBox">
          <div>
            <p>
              <span>专业成就未来</span>
              <span>一站式专业平台</span>
            </p>
          </div>
          <div>
            香港红狮集团有限公司还为客户提供全面的资讯服务，独到的行情走势分析，重要经济播报和专业在线咨询等，全方位满足投资者的需求。公司采用全球领先的MT4交易平台，为投资者提供一站式现货投资服务。公司交易平台的稳定性更是备受市场赞誉，支持一秒平仓、EA智能交易，这些都对平台的稳定性有着高度的技术要求，而我们致力于超越行业技术标准。
            <br/>
            展望未来，香港红狮集团有限公司全体同仁将继续实践客户至上的经营理念，努力开拓创新，不断完善和提升产品服务，以帮助投资者实现梦想，共创美好生活。
          </div>
        </div>
        <img src="../../static/aboutus/pingxing2.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Aboutus',
    metaInfo: {  
      title: '关于红狮金业,现货黄金开户平台,伦敦金,伦敦银,安全持牌黄金交易平台',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易,贵金属投资,贵金属平台,现货黄金开户,正规靠谱外汇交易,正规网上交易平台。' },  
        { name: 'description', content: '红狮金业是专业值得信赖的国际投资交易平台，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },  
    data() {
      return{

      }
    },
    created(){
      console.log(Number(sessionStorage.getItem('gold')));
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
    }
  }
</script>

<style lang="less" scoped>
  .Aboutus{
    width: 100%;
    margin: 0 auto;
    .bigImg{
      width: 100%;
      height: 500px;
      background: url('../../static/aboutus/banner.jpg') no-repeat;
      background-size: cover;
    }
    .iconBox{
      width: 1328px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 80px 20px;
      div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
          width: 145px;
          height: 145px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          // box-shadow: 10px 10px 5px #888888;
          box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
          img{
            width: 55px;
            height: auto;
          }
        }
        span{
          font-size: 18px;
          font-weight: 600;
          line-height: 40px;
          color: #121212;
        }
      }
    }
    .contentBox{
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 20px;
      background: url('../../static/aboutus/quxian_0.png') no-repeat;
      background-size: cover;
      .letterBox{
        width: 1327px;
        height: 344px;
        margin: 0 auto;
        margin-bottom: 70px;
        box-sizing: border-box;
        padding:0 80px;
        background: url('../../static/aboutus/juxing.jpg') no-repeat;
        background-size: cover;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .lbSmallBox{
          div:nth-child(1){
            display: flex;
            justify-content: flex-start;
            align-items: center;
            p{
              display: flex;
              height: 50px;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              span{
                font-weight: 600;
                font-size: 14px;
                color: #6d6d6d;
              }
              span:nth-child(1){
                font-size: 20px;
                color: #2e2e2e;
              }
            }
          }
          div:nth-child(2){
            width: 690px;
            margin-top: 10px;
            font-size: 14px;
            color: #6d6d6d;
            line-height: 30px;
            // text-align: justify;
          }
        }
        img{
          width: 363px;
          height: auto;
        }
      }
    }
  }

</style>